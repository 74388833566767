import React from 'react'
import { Title } from '../Shared/SectionHeader.styles'
import { Row, Col, Container } from '../UI/Grid'
import { Section } from '../UI/Section'
import Element from '../UI/Element'
import CheckCircleIcon from '../Icons/CheckCircleIcon'
import { StaticImage } from 'gatsby-plugin-image'
import ScaledImage from '../UI/ScaledImage'

const Compliance = () => {
  return (
    <Section id="conflicts-mineral-policy">
      <ScaledImage bg="true">
        <StaticImage src="../../images/bg-compliance.jpg" alt="bg" />
      </ScaledImage>
      <Container>
        <Row>
          <Col col={{ _: 10, xs: 9, sm: 8, md: 6, xl: 5 }}>
            <Title mb={3}>European Union Directive 2015/863 Compliance</Title>
            <Row gutterSize={2} mt={{ _: 1, lg: 0 }} mb={10}>
              <Col col="auto">
                <Element color="brand" fontSize="20px" lineHeight="1">
                  <CheckCircleIcon />
                </Element>
              </Col>
              <Col col="fill">
                Fastech supports the aims and objectives of the European Union (EU) on the
                Restriction of Hazardous Substances Directive (RoHS) and Pb free compliance: A
                directive that restricts the use of Cadmium (Cd), Lead (Pb), Mercury (Hg),
                Hexavalent Chromium: (Cr VI), Polybrominated Biphenyls (PBB), Polybrominated
                Diphenyl Ethers (PBDE), Bis(2-Ethylhexyl) phthalate (DEHP), Benzyl butyl phthalate
                (BBP), Dibutyl phthalate (DBP), and Diisobutyl phthalate (DIBP).
              </Col>
            </Row>

            <Title mb={3}>Green Molding Compound Utilization</Title>
            <Row gutterSize={2} mt={{ _: 1, lg: 0 }}>
              <Col col="auto">
                <Element color="brand" fontSize="20px" lineHeight="1">
                  <CheckCircleIcon />
                </Element>
              </Col>
              <Col col="fill">
                Green molding compounds is a halogen and antimony free formulation that improves the
                performance of the component. By replacing the halogen, which is highly reactive,
                the impact on units' reliability gets even better. At the same time, the environment
                and health hazards are also addressed. As part of the Semiconductor Industry,
                Fastech does its share in pushing the green partners, RoHS & REACH compliance for a
                common goal - To protect the environment and human health.
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Compliance
