import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Banner from '../Shared/Banner'
import ScaledImage from '../UI/ScaledImage'

const WhyFastechBanner = () => {
  return (
    <Banner
      name="Our Advantages"
      description="Leadership and Excellence in<br/>Electronic Manufacturing Services"
    >
      <ScaledImage bg={true}>
        <StaticImage src="../../images/banner-why-fastech.jpg" alt="banner" layout="fullWidth" />
      </ScaledImage>
    </Banner>
  )
}

export default WhyFastechBanner
