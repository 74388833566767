import React from 'react'
import { Section } from '../UI/Section'
import ScaledImage from '../UI/ScaledImage'
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Row, Col } from '../UI/Grid'
import CertificationList from '../Shared/CertificationList'
import { Title } from '../Shared/SectionHeader.styles'
import CheckCircleIcon from '../Icons/CheckCircleIcon'
import Element from '../UI/Element'

const Certification = () => {
  return (
    <Section>
      <ScaledImage bg={true}>
        <StaticImage src="../../images/bg-certification-alt.jpg" alt="bg" layout="fullWidth" />
      </ScaledImage>

      <Container color="white">
        <Row justifyContent="center" textAlign="center" mb={{ _: 10, lg: 12 }}>
          <Col col={{ lg: 9 }}>
            <Title mb={{ _: 10, lg: 12 }} color="white">
              IATF and ISO Certifications
            </Title>
            <CertificationList />
          </Col>
        </Row>

        <Row rowGap={10}>
          <Col col={{ lg: 6 }}>
            <Row gutterSize={2} mt={{ _: 1, lg: 0 }}>
              <Col col="auto">
                <Element fontSize="20px" lineHeight="1">
                  <CheckCircleIcon />
                </Element>
              </Col>
              <Col col="fill">
                <b>IATF 16949:2016/ISO 9001:2015 Quality Management Systems (QMS) certification:</b>
                <br />
                <br />
                A quality management standard for the automotive industry and a requirement to be
                fully qualified as an automotive supplier.
                <br />
                <br />
                Fastech has been qualified for the IATF 16949 since 2007 but was only limited to
                automotive sensors packages. In 2018, the company expanded its certification to
                discrete packages. Now it includes both discrete packages and automotive sensors
                packages.
              </Col>
            </Row>
          </Col>
          <Col col={{ lg: 6 }}>
            <Row gutterSize={2} mt={{ _: 1, lg: 0 }}>
              <Col col="auto">
                <Element fontSize="20px" lineHeight="1">
                  <CheckCircleIcon />
                </Element>
              </Col>
              <Col col="fill">
                <b>ISO 14001:2015 Environmental Management Systems (EMS) certification:</b>
                <br />
                <br />A management standard that states the requirements for the EMS of any
                organization.
                <br />
                <br />
                As part of the company's mission, Fastech is committed to sustainability. Fastech
                takes great consideration of the industry's environmental impacts and is consciously
                taking steps to reduce and prevent its effects.
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Certification
