import React from 'react'
import { Title } from '../Shared/SectionHeader.styles'
import { Row, Col, Container } from '../UI/Grid'
import { Section } from '../UI/Section'
import { Card, CardContent } from '../UI/Card'
import { Paragraph } from '../UI/Typography'
import styled from 'styled-components'
import ScaledImage from '../UI/ScaledImage'
import { StaticImage } from 'gatsby-plugin-image'
import theme from '../../utils/theme'
import Button from '../UI/Button'
import Element from '../UI/Element'
import DefaultVector from '../Shared/DefaultVector'
import { Link } from 'gatsby'

const Strategic = () => {
  return (
    <Section defaultVector py={{ _: 16, lg: 0 }} bg="gray">
      <DefaultVector>
        <Container>
          <Row rowGap={6} mb={{ lg: 20 }}>
            <Col col={{ md: 4 }}>
              <Title mb={{ _: 6, lg: 10 }}>
                4S:&nbsp;
                <Element as="span" display={{ _: 'none', md: 'block' }} />
                Strategic&nbsp;
                <Element as="span" display={{ _: 'none', md: 'block' }} />
                Secure&nbsp;
                <Element as="span" display={{ _: 'none', md: 'block' }} />
                Supply&nbsp;
                <Element as="span" display={{ _: 'none', md: 'block' }} />
                Sourcing
              </Title>
              <Button as={Link} variant="brand" to="/contact-us">
                Inquire Now
              </Button>
            </Col>
            <Col col={{ md: 8 }}>
              <Row rowGap={{ _: 6, lg: 20 }}>
                <Col col={{ sm: 6 }}>
                  <StrategicCard cardhidden="true" cardFull>
                    <ScaledImage bg={true}>
                      <StaticImage
                        src="../../images/bg-strategic-1.jpg"
                        alt="banner"
                        layout="fullWidth"
                      />
                    </ScaledImage>
                    <CardContent py={8}>
                      <Element mb={4} textAlign="center">
                        <StaticImage
                          src="../../images/cog-desktop.png"
                          alt="arrow times"
                          width={90}
                          height={90}
                        />
                      </Element>
                      <Paragraph>
                        Fastech established what it calls the Strategic Secure Supply Sourcing or
                        4S. This ensures that at least three sources, preferably from three
                        different countries, are available for raw materials that Fastech identifies
                        to be critical.
                      </Paragraph>
                    </CardContent>
                  </StrategicCard>
                </Col>
                <Col col={{ sm: 6 }}>
                  <StrategicCard cardhidden="true" cardFull>
                    <ScaledImage bg={true}>
                      <StaticImage
                        src="../../images/bg-strategic-2.jpg"
                        alt="banner"
                        layout="fullWidth"
                      />
                    </ScaledImage>
                    <CardContent py={8}>
                      <Element mb={4} textAlign="center">
                        <StaticImage
                          src="../../images/arrow-times.png"
                          alt="cog desktop"
                          width={90}
                          height={90}
                        />
                      </Element>
                      <Paragraph>
                        This strategy safeguards the company's ability to maintain its production
                        output and significantly reduced any disruption on its shipments to
                        customers even during a global crisis. This guarantees that critical
                        shipments go out on time.
                      </Paragraph>
                    </CardContent>
                  </StrategicCard>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </DefaultVector>
    </Section>
  )
}

const StrategicCard = styled(Card)`
  background-color: ${theme.colors.brand};
  ${CardContent} {
    position: relative;
    z-index: 1;
    color: white;
  }
`

export default Strategic
