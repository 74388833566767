import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import SectionHeader from '../Shared/SectionHeader'
import { Card, CardContent } from '../UI/Card'
import Element from '../UI/Element'
import { Row, Col, Container } from '../UI/Grid'
import ScaledImage from '../UI/ScaledImage'
import { Section } from '../UI/Section'
import { Heading } from '../UI/Typography'

const QualityPolicy = () => {
  return (
    <Section overlay="true" id="quality-policy" py={{ _: 16, lg: '96px' }} color="white">
      <ScaledImage bg={true}>
        <StaticImage src="../../images/bg-quality-policy.jpg" alt="bg" layout="fullWidth" />
      </ScaledImage>
      <Container>
        <Row justifyContent="center" textAlign="center" mb={10}>
          <Col col={{ lg: 8 }}>
            <SectionHeader
              subtitle="COMPANY STANDARDS"
              title="Quality Policy"
              description="The Fastech organization has dedicated itself to quality as a way of life. Quality is the responsibility of every Fastech employee. Total Quality Management (TQM) serves as the firm foundation upon which the company operates and continuously builds its business."
              inverse
            />
          </Col>
        </Row>

        <Row rowGap={6}>
          <Col col={{ sm: 6, md: 4 }}>
            <Card height="100%" color="text">
              <CardContent>
                <Element mb={4} textAlign="center">
                  <StaticImage src="../../images/shield.png" alt="shield" height={64} width={64} />
                </Element>
                <Heading size="h6" mb={4}>
                  Quality in Fastech is:
                </Heading>
                <Element as="ul" pl={4} mb={0}>
                  <li>
                    An essential attribute for the company's lasting corporate success and
                    excellence.
                  </li>
                  <li>A constant norm in all aspects of our operation.</li>
                  <li>A way of life everyone must uphold.</li>
                </Element>
              </CardContent>
            </Card>
          </Col>
          <Col col={{ sm: 6, md: 4 }}>
            <Card height="100%" color="text">
              <CardContent>
                <Element mb={4} textAlign="center">
                  <StaticImage
                    src="../../images/office-building.png"
                    alt="office building"
                    height={64}
                    width={64}
                  />
                </Element>
                <Heading size="h6" mb={4}>
                  It is our firm CORPORATE BELIEF that;
                </Heading>
                <Element as="ul" pl={4} mb={0}>
                  <li>
                    QUALITY is the conformance of our products and services to establish standards
                    that we and our customer require as a matter of course.
                  </li>
                  <li>
                    ZERO-DEFECT(ZD) and ON-TIME DELIVERY are basic standards of quality for all our
                    products and services.
                  </li>
                </Element>
              </CardContent>
            </Card>
          </Col>
          <Col col={{ sm: 6, md: 4 }}>
            <Card height="100%" color="text">
              <CardContent>
                <Element mb={4} textAlign="center">
                  <StaticImage
                    src="../../images/raise-hand.png"
                    alt="hands"
                    height={64}
                    width={64}
                  />
                </Element>
                <Heading size="h6" mb={4}>
                  We dedicate ourselves to:
                </Heading>
                <Element as="ul" pl={4} mb={0}>
                  <li>Do things right, the first time every time.</li>
                  <li>Eliminate the cost of wastes.</li>
                  <li>
                    Continually improve our quality management system, processes, and product
                    quality.
                  </li>
                  <li>
                    And create a work environment that is based in sincerity, perseverance, honesty,
                    team-effort and open-mindedness.
                  </li>
                </Element>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default QualityPolicy
