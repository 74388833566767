import React from 'react'
import SectionHeader from '../Shared/SectionHeader'
import Element from '../UI/Element'
import { Row, Col, Container } from '../UI/Grid'
import { Section } from '../UI/Section'
import { Paragraph } from '../UI/Typography'
import CheckCircleIcon from '../Icons/CheckCircleIcon'
import ShadowedImage from '../Shared/ShadowedImage'
import { StaticImage } from 'gatsby-plugin-image'
import DefaultVector from '../Shared/DefaultVector'

const PeopleDevelopment = () => {
  return (
    <Section py={{ _: 16, lg: 0 }}>
      <DefaultVector>
        <Container>
          <Row mb={15} rowGap={6}>
            <Col col={{ md: 6 }}>
              <SectionHeader
                subtitle="PEOPLE DEVELOPMENT"
                title="Human Resources"
                description=" Fastech gives high regard to human resource training and development. It firmly believes that the key to being competitive in the industry is efficiency and quality consciousness among its personnel."
              />
              <Row gutterSize={2} mt={4}>
                <Col col="auto">
                  <Element color="brand" fontSize="20px" lineHeight="1">
                    <CheckCircleIcon />
                  </Element>
                </Col>
                <Col col="fill">
                  <Paragraph>
                    The company consciously improves its human resource management and development
                    programs. Employees undergo technical trainings to respond to the present
                    demands of the industry. An example of which is the IPC- J Standard training.
                    And in addition to such trainings, Fastech also has a Continuing Education
                    program that offers an Expanded Tertiary Education Equivalency and Accreditation
                    Program (ETEEAP).
                  </Paragraph>
                </Col>
              </Row>
            </Col>
            <Col col={{ md: 6 }}>
              <ShadowedImage>
                <StaticImage
                  src="../../images/human-resources-1.jpg"
                  alt="banner"
                  layout="fullWidth"
                />
              </ShadowedImage>
            </Col>
          </Row>

          <Row mb={15} rowGap={6} alignItems="center">
            <Col col={{ md: 6 }} order={{ md: 9999 }}>
              <Row gutterSize={2} mt={4}>
                <Col col="auto">
                  <Element color="brand" fontSize="20px" lineHeight="1">
                    <CheckCircleIcon />
                  </Element>
                </Col>
                <Col col="fill">
                  <Paragraph>
                    Aside from this, Fastech employees also go through values development training
                    with the aim of increasing productivity and emphasizing the importance of
                    Quality in all aspects and areas of operation. An example of this is the
                    company’s KAIZEN Your Life, a program which focuses on fundamentals of
                    continuous improvements.
                  </Paragraph>
                </Col>
              </Row>
            </Col>
            <Col col={{ md: 6 }}>
              <ShadowedImage position="left">
                <StaticImage
                  src="../../images/human-resources-2.jpg"
                  alt="banner"
                  layout="fullWidth"
                />
              </ShadowedImage>
            </Col>
          </Row>
        </Container>
      </DefaultVector>
    </Section>
  )
}

export default PeopleDevelopment
