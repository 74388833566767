import React from 'react'
import Seo from '../components/Layout/Seo'
import Site from '../components/Layout/Site'
import Certification from '../components/WhyFastech/Certification'
import Competence from '../components/WhyFastech/Competence'
import Compliance from '../components/WhyFastech/Compliance'
import ConflictsPolicy from '../components/WhyFastech/ConflictsPolicy'
import PeopleDevelopment from '../components/WhyFastech/PeopleDevelopment'
import QualityPolicy from '../components/WhyFastech/QualityPolicy'
import Strategic from '../components/WhyFastech/Strategic'
import WhyFastechBanner from '../components/WhyFastech/WhyFastechBanner'

const WhyFastech = () => {
  return (
    <Site>
      <Seo title="Our Advantages" />
      <WhyFastechBanner />
      <Competence />
      <QualityPolicy />
      <ConflictsPolicy />
      <Certification />
      <Compliance />
      <Strategic />
      <PeopleDevelopment />
    </Site>
  )
}

export default WhyFastech
