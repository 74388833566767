import React from 'react'
import { Title } from '../Shared/SectionHeader.styles'
import { Row, Col, Container } from '../UI/Grid'
import { Section } from '../UI/Section'
import { Paragraph } from '../UI/Typography'
import Element from '../UI/Element'
import { StaticImage } from 'gatsby-plugin-image'
import ScaledImage from '../UI/ScaledImage'
import Button from '../UI/Button'
import DownloadIcon from '../Icons/DownloadIcon'
import { graphql, useStaticQuery } from 'gatsby'

const ConflictsPolicy = () => {
  const pdf = useStaticQuery(graphql`
    query pdfQuery {
      allFile(
        filter: {
          relativePath: {
            eq: "Fastech_Conflict_Minerals_Policy_and_Environmental_Management_Policy.pdf"
          }
        }
      ) {
        nodes {
          publicURL
        }
      }
    }
  `)

  return (
    <Section id="conflicts-mineral-policy">
      <ScaledImage bg="true">
        <StaticImage src="../../images/bg-conflicts-mineral-policy.jpg" alt="bg" />
      </ScaledImage>
      <Container>
        <Row>
          <Col col={{ _: 10, xs: 9, sm: 8, md: 6, xl: 5 }}>
            <Title mb={3}>Conflicts Mineral Policy</Title>
            <Paragraph mb={10}>
              As one of the established Semiconductor Company for Assembly and Test in the
              Philippines, support the aims and objectives of the U.S. Legislation on the Supply of
              “Conflict Materials”. We are committed to the responsible sourcing of “Conflict
              Materials” throughout our Supply Chain.
            </Paragraph>

            <Title mb={3}>Environmental Management Policy</Title>
            <Paragraph mb={10}>
              Fastech recognizes the importance of protecting and preserving the environment and
              believes that it is our task to ensure its sustainability for future generations.
            </Paragraph>

            <Title size="h6" mb={5}>
              Download Full Conflict Minerals Policy and Environmental Management Policy Document
              Here:
            </Title>
            <Button
              variant="brand-outline"
              as="a"
              href={pdf.allFile.nodes[0].publicURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Element mr={2}>Download</Element>
              <DownloadIcon />
            </Button>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default ConflictsPolicy
