import React from 'react'
import SectionHeader from '../Shared/SectionHeader'
import Element from '../UI/Element'
import { Row, Col, Container } from '../UI/Grid'
import { Section } from '../UI/Section'
import { Heading, Paragraph } from '../UI/Typography'
import CheckCircleIcon from '../Icons/CheckCircleIcon'
import ShadowedImage from '../Shared/ShadowedImage'
import { StaticImage } from 'gatsby-plugin-image'
import DefaultVector from '../Shared/DefaultVector'

const Competence = () => {
  return (
    <Section py={{ _: 16, lg: 0 }}>
      <DefaultVector>
        <Container>
          <Row mb={15} rowGap={6}>
            <Col col={{ md: 6 }}>
              <SectionHeader
                subtitle="COMPETENCE"
                title="Manufacturing Excellence"
                description="We continue to develop and improve processes and product to better serve the various needs of our clients."
              />
              <Row gutterSize={2} mt={4}>
                <Col col="auto">
                  <Element color="brand" fontSize="20px" lineHeight="1">
                    <CheckCircleIcon />
                  </Element>
                </Col>
                <Col col="fill">
                  <Heading size="h6" mb={2}>
                    Flexibility and Product Ownership
                  </Heading>
                  <Paragraph>
                    We acknowledge the unique requirement by offering services particular to the
                    specification and needs of our customers. We work to reduce costs and share the
                    savings.
                  </Paragraph>
                </Col>
              </Row>
              <Row gutterSize={2} mt={4}>
                <Col col="auto">
                  <Element color="brand" fontSize="20px" lineHeight="1">
                    <CheckCircleIcon />
                  </Element>
                </Col>
                <Col col="fill">
                  <Heading size="h6" mb={2}>
                    Cost-competitiveness
                  </Heading>
                  <Paragraph>
                    We give the most cost-effective solutions by optimizing operational efficiencies
                    and through product development and engineering.
                  </Paragraph>
                </Col>
              </Row>
            </Col>
            <Col col={{ md: 6 }}>
              <ShadowedImage>
                <StaticImage
                  src="../../images/manufacturing-excellence.jpg"
                  alt="banner"
                  layout="fullWidth"
                />
              </ShadowedImage>
            </Col>
          </Row>

          <Row mb={15} rowGap={6}>
            <Col col={{ md: 6 }} order={{ md: 9999 }}>
              <SectionHeader
                title="Customer Relations Excellence"
                description="Fastech provides superior customer support. We take pride in our reputation of being a quality, service and people-oriented company."
              />
              <Row gutterSize={2} mt={4}>
                <Col col="auto">
                  <Element color="brand" fontSize="20px" lineHeight="1">
                    <CheckCircleIcon />
                  </Element>
                </Col>
                <Col col="fill">
                  <Heading size="h6" mb={2}>
                    Customer Retention
                  </Heading>
                  <Paragraph>
                    Fastech’s strength lies in its ability to maintain long-term relationships with
                    customers. The Company’s Sales, Marketing and Customer Relations team are fully
                    committed to understanding and meeting the growing needs of our customers.
                  </Paragraph>
                </Col>
              </Row>
              <Row gutterSize={2} mt={4}>
                <Col col="auto">
                  <Element color="brand" fontSize="20px" lineHeight="1">
                    <CheckCircleIcon />
                  </Element>
                </Col>
                <Col col="fill">
                  <Heading size="h6" mb={2}>
                    Flexibility and Product Ownership
                  </Heading>
                  <Paragraph>
                    The Company regularly conducts surveys among its clients to determine possible
                    areas for improvement, and additional capabilities that the customers require as
                    well as their other needs.
                  </Paragraph>
                </Col>
              </Row>
            </Col>
            <Col col={{ md: 6 }}>
              <ShadowedImage position="left">
                <StaticImage
                  src="../../images/customer-relations-excellence.jpg"
                  alt="banner"
                  layout="fullWidth"
                />
              </ShadowedImage>
            </Col>
          </Row>
        </Container>
      </DefaultVector>
    </Section>
  )
}

export default Competence
