import React from "react";
import Icon from "../UI/Icon";

const CheckCircleIcon = () => {
  return (
    <Icon viewBox="0 0 24 24">
      <path d="M12 2.5C6.48 2.5 2 6.98 2 12.5C2 18.02 6.48 22.5 12 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 12 2.5ZM9.29 16.79L5.7 13.2C5.31 12.81 5.31 12.18 5.7 11.79C6.09 11.4 6.72 11.4 7.11 11.79L10 14.67L16.88 7.79C17.27 7.4 17.9 7.4 18.29 7.79C18.68 8.18 18.68 8.81 18.29 9.2L10.7 16.79C10.32 17.18 9.68 17.18 9.29 16.79Z" />
    </Icon>
  );
};

export default CheckCircleIcon;
